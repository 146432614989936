import { get, post } from "@/utils/axios";

export async function fetchGroupList(userGuid) {
  let groupList = await get("/group/GroupList", {
    t: new Date().getTime(),
    customerGuid: userGuid
  });
  return groupList.itemList;
}

export async function fetchGroupName(groupGuid) {
  let response = await get("/group/GroupName", {
    t: new Date().getTime(),
    groupGuid
  });
  return response.groupName;
}

export function fetchGroupDetails(groupGuid) {
  return get("/group/GroupDetails", {
    t: new Date().getTime(),
    groupGuid
  });
}

export async function autoGenerateGroupTestPwd(userGuid) {
  let response = await post(
    "/group/GenerateNewTestPwd",
    JSON.stringify(userGuid)
  );
  return { groupTestPwd: response.value };
}

export async function checkGroupTestPwdDuplicated(testPwd) {
  let response = await post(
    "/group/IsTestPwdDuplicate",
    JSON.stringify(testPwd)
  );
  return { isDuplicated: response.value };
}

export function saveInfoChanges(
  groupGuid,
  { groupName, groupTestPwd, showScaReport, isContinuousTest, qrExpireSeconds }
) {
  return post("/group/SaveGroupInfo", {
    groupGuid,
    groupName,
    groupTestPwd,
    showScaReport,
    isContinuousTest,
    qrExpireSeconds
  });
}

export function fetchGroupQrCode({ groupGuid, groupName, groupTestPwd }) {
  return post("/group/MakeQrCode", {
    urlPath: `${process.env.VUE_APP_PUBLIC_PATH}scalogin`,
    testGroupGuid: groupGuid,
    queryName: "cd",
    queryValue: groupTestPwd,
    textEx: groupName
  });
}

export async function createNewGroup(userGuid, groupName, groupTestPwd = null) {
  let response = await post("/group/CreateNewGroupWithInfo", {
    customerGuid: userGuid,
    groupName,
    groupTestPwd
  });
  return response.guid;
}

export function deleteOneGroup(groupGuid) {
  return post("/group/DeleteOneGroup", JSON.stringify(groupGuid));
}
